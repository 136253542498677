.add-customer-text {
    color: var(--secondary-theme);
    line-height: 36px;
    letter-spacing: -0.48px;
}

.next-btn {
    padding: 10px 32px;
    border-radius: 4px;
    background: var(--primary-500);
    border: 0;
    color: var(--white);
    font-weight: 600;
    font-size: 14px;
}
.err_msg {
  color: red;
  font-size: 10px;
  margin-left: 12px;
}

.profile-change-pic {
    padding: 0;
}

.profile-pic {
    background: #D9D9D9;
    border-radius: 100%;
    padding: 22px;
    position: relative;
}

.input-profile,
.profile-pic {
    cursor: pointer;
    height: 127px;
    width: 127px;
    /* border: 1px solid black; */
}

.profile-pic {
    background: #D9D9D9;
    border-radius: 100%;
    padding: 22px;
    position: relative;
}

.profile-pic-change{
      padding: 0px;
  }
.input-profile {
    opacity: 0;
    position: absolute;
    top: 0px;
    z-index: 99;
}

.profile-upper-pic {
    text-align: center;
    padding: 15px 0px;
}

/* .add-customer-text {
    color: var(--secondary-theme);
    line-height: 36px;
    letter-spacing: -0.48px;
  }
  .next-btn {
    padding: 10px 32px;
    border-radius: 4px;
    background: var(--primary-500);
    border: 0;
    color: var(--white);
    font-weight: 600;
    font-size: 14px;
  }
  
  
  .basic-info {
    background: url("../images/fitx-line.svg");
    width: 100%;
    height: 4px;
    background-size: contain;
    position: relative;
  }
  .basic-head {
    position: absolute;
    background-color: #fff;
    bottom: -7px;
    padding: 0 13px 0 0px;
    color: #a5a5a5;
  }
  .time-input {
    width: 45%;
  }
   */
  .Biometric-img {
      padding: 30px 0px;
  }
  .add-biometric{
  color: #767676;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  }
  .personal-heading {
  text-transform: uppercase;
}

form.create-field{
padding: 10px 30px;
}
.purchase-create-field {
  height: 467px;
  overflow-y: auto;
}
.purchase-create-field ~ .submit-head{
  padding: 0px 30px;
}

.modalHeading{
  color: var(--secondary-theme);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.output-text{
  color: #191919;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.label-text{
  color: #B5B5B5;
  font-weight: 500;font-size: 14px;
  line-height: 21px;
}

.img-preview {
  width: 127px;
  border-radius: 100%;
  height: 127px;
  object-fit: cover;
}
.img-preview-customer {
  width: 126px;
  border-radius: 100%;
  height: 126px;
  object-fit: cover;
}
.about-top{
  padding: 0px 0px !important;
}

.add-btn {
  background-color: transparent;
  border: none;
  color: var(--primary-theme);
  font-size: 18px;
  text-transform: uppercase;
}
.add-btn:hover {
  color: var(--primary-theme);
}

.add-btn.add-field{
  padding: 0px 25px
}
.add-btn.add-field:hover{
  background-color: var(--primary-theme);
  color: #fff;
}
.specializations-span{
  border: 1px solid #D1D1D1;
  border-radius: 11px;
  padding: 9px 18px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.modal-header{
  border: 0;
}

.imgbeforeupload {
  position: absolute;
  top: 35%;
  left: 38%;
}
.custom-accordion-item{
  border-top-left-radius:unset;
  border-top-right-radius:unset;
  color:#151829;
  border: unset;
}
.custom-accordion-btn{
  color:#151829;
  background-color:#EFEFEF;
}
.custom-accordion-btn:focus {
  color:#151829;
  box-shadow: unset;
  background-color:#EFEFEF;
}
.custom-accordion-btn:not(.collapsed)::after {
  background-image:var(--bs-accordion-btn-icon);
}
.accordion-button:not(.collapsed) {
  color:#151829;
  box-shadow: unset;
  background-color:#EFEFEF;
}
.custom-biometric{
  width: 24%;
}

@media screen and (max-width:1440px) {
  .specializations-span, .add-btn{
    font-size: 15px;
  }
  .specializations-span{
    padding: 9px 16px;
  }
}
@media screen and (max-width:1200px) {
  .custom-biometric{
    width: 32%;
  }
}
@media screen and (max-width:991px) {
  .specializations-span{
    padding: 8px 14px;
  }
  .custom-biometric{
    width: 49%;
  }
}
@media screen and (max-width:576px) {
  .custom-biometric{
    width: 100%;
  }
  .purchase-modal{
    width: 98% !important;
  }
}
@media screen and (max-width:320px) {
  .add-btn.add-field{
    padding: 0px 13px;
    font-size: 14px;
  }
}
