.dash-card {
  border-radius: 4px;
  background: var(--white);
}
.dash-img img {
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid var(--secondary-200);
}
.dash-head {
  color: var(--primary-theme);
  font-size: 16px;
  letter-spacing: -0.32px;
}
.dash-text {
  color: var(--secondary-theme);
  font-size: 22px;
  letter-spacing: -0.72px;
}
.dash-common-text{
    color: var(--secondary-theme);
    letter-spacing: -0.36px;
}
.graph-orange-dot{
    width:15px;
    height: 15px;
    background-color: var(--primary-theme);
    border-radius: 100%;
}
.graph-red{
    background-color:#B43418;
}
.graph-label-text{
    color: var(--secondary-theme);
    letter-spacing: -0.24px;
}
.graph-percentage{
    color: var(--success-600);
}
.graph-percentage-red{
    color: var(--error-600);
}
.graph-customer{
    color: var(--secondary-400);
}
.dash-upgrade{
    font-size: 18px !important;
}
.dash-see{
    color: var(--secondary-theme);
}
.leads-red{
    color: #CF0000;
}
.leads-green{
    color: #2EB200;
}
.leads-blue{
    color: #00A6DB;
}
.upgrade-leads-red{
    border-radius: 2.808px;
opacity: 0.5;
background: #CF0000;
}
.upgrade-leads-green{
    border-radius: 2.808px;
    opacity: 0.5;
    background: #2EB200;
}
.upgrade-leads-blue{
    border-radius: 2.808px;
    opacity: 0.5;
    background: #00A6DB;
}
.upgrade-white{
    border-radius: 4px;
    border: 0.3px solid #959595;
    background: var(--white);
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding: 8px 15px;
}
.customer-date-text{
    color: var(--secondary-theme)
}
.close-text {
    border-radius: 4px;
    background:var(--primary-theme);
    color: var(--white);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.24px;
    padding: 8px 15px;
  }


  .filter-container .filters-text , .filter-icon {
   
        color: var(--secondary-theme);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.48px;
        cursor: pointer;
    
  }

  .filter-container .custom-filter-dropdown{
position: absolute;
right: 1rem;
padding: 1rem;
background-color: var(--white);
z-index: 9999;
}


.filter-container .custom-select{
  width: 250px;
  height: auto;
}

.filter-container .filter-btn{
    background-color: var(--primary-theme);
    color: var(--white);

}
.count-card {
    width: 313px; 
}

.row.flex-nowrap {
    scroll-behavior: smooth;
}
.row.flex-nowrap.overflow-auto {
    overflow-x: auto; 
    scrollbar-width: thin; 
    scrollbar-color: #ccc transparent;
}

.row.flex-nowrap.overflow-auto::-webkit-scrollbar {
    height: 8px;
}

.row.flex-nowrap.overflow-auto::-webkit-scrollbar-thumb {
    background-color: #ccc; 
    border-radius: 4px; 
}

.row.flex-nowrap.overflow-auto::-webkit-scrollbar-track {
    background-color: transparent; 
}

.row.flex-nowrap.overflow-auto {
    scroll-snap-type: x mandatory; 
}

.count-card {
    scroll-snap-align: start; 
}

@media (max-width: 1358px) {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
  
    .col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .dash-card { 
      margin-bottom: 20px; 
    }
  }
  
  @media (max-width: 659px) {
    .col-lg-3 {
      flex: 0 0 100%; 
      max-width: 100%;
    }
  }
  
