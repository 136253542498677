  .leads-table-responsive thead th {
    text-align: center;
  }

  .leads-table-responsive thead th:nth-child(1) {
    text-align: start !important;
  }

  .leads-table-responsive tbody td {
    text-align: center;
  }

  .main-height {
    min-height: calc(100vh - 239.19px);
    height: auto;
  }

  .leads-table {
    border-radius: 4px;
    background: var(--white);
    padding: 15px;
  }

  .leads-btn {
    border-radius: 4px;
    border: 1px solid var(--secondary-200);
    color: var(--secondary-theme);
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 10px 22px;
  }

  .leads-head h3 {
    color: var(--secondary-theme);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.48px;
  }

  .add-leads-btn {
    border-radius: 4px;
    background: var(--primary-500);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    padding: 8px 24px 8px 8px;
  }

  .upgrade-text {
    border-radius: 4px;
    background: #151829;
    color: var(--white);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.24px;
    padding: 8px 15px;
  }

  .leads-table-data {
    color: var(--black);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.28px;
  }

  .leads-table-head {
    color: var(--secondary-400);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: uppercase;
  }

  .leads-data-head {
    color: var(--secondary-theme);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.28px;
  }

  .add-leads-btn:hover,
  .add-leads-btn:active {
    background: var(--primary-500) !important;
    color: var(--white) !important;
    border-color: none !important;
  }

  .dropdown-toggle::after {
    margin-left: 1em;
    content: "";
    vertical-align: middle;
    background-image: url("../images/drop_down_icon.svg");
    border-right: unset !important;
    border-left: unset !important;
    border-top: unset !important;
    width: 10px;
    height: 8px;
    background-repeat: no-repeat;
  }

  .table> :not(caption)>*>* {
    padding: 1rem 0.5rem !important;
  }

  .tbody tr td {
    text-align: center;
  }

  /* pagination */
  .page-entry {
    color: var(--secondary-300);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .page-prev-btn {
    color: var(--secondary-300);
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    margin: 0px 10px;
  }

  .page-next-btn {
    color: var(--secondary-300);
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    margin: 0px 10px;
  }

  .page-count.active {
    background: var(--primary-500);
    color: var(--white);
    border: 1px solid transparent;
  }

  .page-count {
    border-radius: 4px;
    background: var(--white);
    padding: 6px 14px;
    color: var(--black);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    border: 1px solid var(--secondary-200);
  }

  .page-link:hover {
    background-color: transparent;
  }

  .page-prev-btn:active,
  .page-prev-btn:hover,
  .page-next-btn:active,
  .page-next-btn:hover {
    color: var(--primary-theme);
  }

  .leads-table-responsive table tr th,
  .leads-table-responsive table tr td {
    vertical-align: middle;
  }

  .no-data-text {
    color: #bfbfbf;
    font-size: 24px;
    font-style: normal;
    line-height: 36px;
    letter-spacing: -0.48px;
  }

  .customer-pic {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: none;
    object-fit: cover;
  }

  .delete-btn {
    border-radius: 4px;
    background: var(--primary-500);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    padding: 10px 30px;
  }

  .delete-btn:hover {
    background: var(--primary-500);
    color: var(--white);
  }

  .signin-head h2 {
    color: var(--secondary-theme);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.48px;
  }

  .modal-head p {
    font-size: 14px;
    max-width: 394px;
    text-align: center;
    margin: auto;
    color: #767676;
    word-break: break-all;
  }

  /* add staff details modal start */
  .close-modal-btn {
    position: absolute;
    right: 6px;
    top: 9px;
  }

  .staff-modal-li li {
    border-right: 1px solid #767676;
  }

  .staff-modal-li li:last-child {
    border-right: 0;
  }

  .staff-modal-li .contact-li:last-child {
    padding-right: 0;
  }

  .staff-modal-li li p {
    padding: 0px 9px;
    line-height: 15px;
  }

  .staff-modal-li li h6 {
    padding: 0px 9px;
    line-height: 15px;
  }

  .text-grey {
    color: #767676;
  }

  .contact-li h6 {
    line-height: 25px;
  }

  li.contact-li p {
    line-height: 24px;
  }

  .specializations-span {
    border: 1px solid #D1D1D1;
    border-radius: 11px;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
  }

  .f-16 {
    font-size: 16px;
  }

  .user-profile {
    width: 100px;
    overflow: hidden;
    height: 100px;
    margin: auto;
  }

  .staff-profile-before {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .modal-head p.trainerAbout {
    max-width: 100%;
  }

  .border-bottom {
    border-bottom: 1px solid #D9D9D9;
  }

  .text-black {
    color: #000;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width:575px) {
    .user-profile {
      width: 80px;
      height: 80px;
    }

    .css-11uaazw {
      max-width: 95% !important;
    }
  }

  @media screen and (max-width:480px) {
    .contact-li h6 {
      font-size: 14px;
    }

    .user-profile {
      width: 60px;
      height: 60px;
    }

    .user-name {
      font-size: 18px;
    }

    .email-text.f18-size {
      font-size: 14px;
    }

  }

  @media screen and (max-width:393px) {
    .contact-li {
      padding-right: 5px;
    }

    .responsive-staff-li {
      flex-wrap: wrap;
      justify-content: center !important;
      gap: 10px;
    }

    .specializations-span {
      padding: 8px 8px;
      font-size: 12px;
    }
  }

  @media screen and (max-width:320px) {

    .contact-li h6 {
      font-size: 12px;
    }

    li.contact-li p {
      padding: 0;
      font-size: 14px;
    }

    .address-detail.px-2 {
      padding: auto 0 !important;
    }
  }

  .user-profile-before {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
  }

  /* add staff details modal end */
  .remarktip {
    position: relative;
    display: inline-block;
  }

  .remarktip .remarktiptext {
    visibility: hidden;
    max-width: 450px;
    background-color: var(--primary-theme);
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 3px 10px;
    position: absolute;
    top: 30px;
    font-size: 14px;
    word-break: break-all;
    z-index: 1000000000000 !important;
    right: 0px;
    min-width: 100px;
    width: max-content;
  }

  .remarktip .remarktiptext::after {
    content: "";
    position: absolute;
    top: -10px;
    right: 9px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary-theme) transparent;
  }

  .remarktip:hover .remarktiptext {
    visibility: visible;
  }

  .notFound {
    text-align: center !important;
    font-size: 20px;
    color: var(--secondary-theme);
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.52px;
    margin: 50px auto;
  }

  /* membership table css */
  .due-balance {
    border-radius: 5px;
    background: rgba(212, 0, 0, 0.15);
    color: #d40000;
    padding: 3px 12px;
    min-width: 74px;
  }

  .pay-balance {
    border-radius: 5px;
    background: var(--success-600);
    color: #fff;
    padding: 3px 12px;
    width: 74px;
  }

  .pay-balance:hover {
    color: #fff;
  }

  .pay-date {
    color: #808080;
  }

  .disbale {
    background-color: #808080;
  }

  .membership-disable {
    background: #808080 !important;

  }

  .purchase-modal-fields {
    height: 250px !important;
    overflow-y: auto;
  }

  .purchase-modal-heads {
    height: 450px !important;
  }

  .leads-table-align {
    vertical-align: top !important;
  }

  .btn-active {
    color: var(--secondary-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding: 0px;
  }

  .btn-active.border-right {
    border-right: 1px solid #ddd;
  }

  .btn-active .dropall {
    background: transparent !important;
    color: var(--secondary-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    padding: 0px;
    border: 0;
  }

  .f13 {
    font-size: 13px;
  }

  .close-btn-date {
    top: 5px;
    right: 0;
  }

  .btn-active.active,
  .btn-active:hover {
    color: var(--primary-theme) !important;
  }

  .dropdown.active .btn-active {
    color: var(--primary-theme) !important;
  }

  .btn-active:hover .dropall {
    color: var(--primary-theme) !important;
  }

  @media(max-width:768px) {
    .modalcustomdate .modal-head {
      width: 100% !important;
    }
  }

  .custom_date {
    background: #FEF0E8;
  }

  .calender {
    height: 15px;
    width: 20px;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-container .tooltip-text {
    visibility: hidden;
    width: 350px;
    background-color: var(--primary-500);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 30px;
    position: absolute;
    z-index: 9999;
    top: 125%;  /* Tooltip initially below */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    opacity: 0;
    transition: opacity 0.3s;
}
 .tooltip-text-width {
    width: 200px !important;
  
}

.tooltip-container .tooltip-text::after {
    content: '';
    position: absolute;
    top: -10px; /* Arrow pointing down below tooltip */
    left: 50%;
    margin-left: -5px; /* Centers the arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent var(--primary-500) transparent; /* Creates the downward triangle */
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Tooltip above for the last row */
.last-row .tooltip-container .tooltip-text {
    top: auto;
    bottom: 125%;  /* Move tooltip above the element */
    left: 50%;
    transform: translateX(-50%);
}

.last-row .tooltip-container .tooltip-text::after {
    top: auto;
    bottom: -10px; /* Arrow now at the bottom of tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: var(--primary-500) transparent transparent transparent; /* Creates the upward triangle */
}
.totaolattend img {
  width: 20px;
  object-fit: contain;
}
.totaolattend{
  background: #F2F2F2;
  border-radius: 15px;
  padding: 15px;
}



@media (max-width: 600px) {
 
}
.orange-btn {
  color: #EB4D2A;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 9px;
  letter-spacing: -0.18px;
  border-radius: 20px;
  background: #eb4c281a;
  padding: 10px 19px;
}
.green-btn{
    color: #02AE66;
    background: #02b1681a;
}
.blue-btn{
  background: #288deb1a;
  color: #2A8EEB;
}