.leads-table {
    border-radius: 4px;
    background: var(--white);
    padding: 15px;
  }

  .trainer-height{
    min-height: 48vh;
    max-height: 55vh;
    overflow: hidden;
    overflow-y: scroll;
  
  }
  .training-card {
    display: flex;
    gap: 18px;
    align-items: center;
    flex-wrap: wrap;
}
  .detailimg {
    height: 180px;
    max-width: 100%;
    border-radius: 20px;
    width: 100%;
  }

  .input-profile{
    left: 0;
  }

  .profile-upper-pic.execise-img img{
    height: 200px;
    width: 100%;
  }

  .exercisCard .training-card-head {
    width: 282px;overflow: hidden;
  }
  .exercisCard .training-demo {
    width: 281px;
  }
  .exercisCard .training-card-content h4{
    margin: 10px 0;
  }