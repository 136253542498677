
/* .mainDiv {
    height: 3px;
    width: 100%;
    background-color: whitesmoke;
    margin: 4px 0;
}

.childDiv {
    height: 100%;
    width: 60%;
    background-color: var(--primary-theme);
    text-align: left;
}

.mainDiv .text {
    color: white;
    padding-left: 30px;
    font-size: 1.4rem;
    font-weight: 700;
} */
/* Assuming the class name for the input element is "spinner" */
/* Assuming you want to hide the spinner arrows for all input elements with type number */

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* For modern browsers like Chrome, Safari, and Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

input[type="range"]:not(:-webkit-slider-runnable-track:disabled)::before {
  content: "";
  display: block;
  height: 4px; 
 
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px; 
  border-radius: 50%;
  cursor: pointer;
}

/* Hide thumb when value is zero */
input[type="range"][value="0"]::-webkit-slider-thumb {
  display: none;
}

.progress-container {
  height: 3px;
  width: 100%;
  background-color: whitesmoke;
  margin: 4px 0;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary-theme);
  text-align: left;
}

.profile-main {
    display: grid;
    grid-template-columns: 129px auto;
    gap: 40px;
    position: relative;
    border-right: 2px solid #dadada;
    padding-right: 50px;
}

.profile-main::after {
    content: "";
    position: absolute;
    border-right: 2px solid #D1D1D1;
}

.active-dot {
    width: 12px;
    height: 12px;
    background: var(--success-600);
    border-radius: 100%;
    outline: 5px solid #33b84347;
}


.profile {
  width: 126px;
  height: 126px;
  overflow: hidden;
  border: 1px solid;
  border-radius: 100%;
  object-fit: cover
}

.social-links {
    gap: 55px;
}

.social-links li {
    position: relative;
    cursor: pointer;
}

.social-links li:after {
    content: "";
    position: absolute;
    border-right: 2px solid #D9D9D9;
    height: 24px;
    right: -40px;
}

.social-links li:last-child::after {
    content: "";
    display: none;
}

.tabs-customer .active {
    background: var(--primary-500) !important;
    border: 0;
    color: var(--white) !important;
    margin-right: 0 !important;

}

.tabs-customer {
    border-right: 1px solid #E2E2E2;
    height: 100%;
    border-bottom: 0;
}

.tabs-customer .nav-link {
    min-height: 75px;
    padding: 10px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-bottom: 1px solid #E2E2E2;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
    border-radius: 0;
}

.tabs-customer .nav-link :last-child {
    border-bottom: 0 !important;
}

.video-select {
    border: 2px dashed #303030;
    border-radius: 8px;
}

.video-upload {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    border: 1px dashed #909090;
    background: #FAFAFB;

}

.video-file {
    width: 100%;
    height: 300px;
    opacity: 0;
    cursor: pointer;
    position: relative;
    z-index: 9999;
}

.upload-img {
    position: absolute;
    top: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
}

.days-content {
    width: 14.28%;
  }
  .days-text {
    text-align: center;
    line-height: 27px;
    letter-spacing: -0.36px;
    color: var(--black);
  }
  .days-content {
    width: 14.28%;
    padding: 20px 10px;
    border-right: 2px solid #d9d9d9;
  }
  .days-head{
      border-bottom:2px solid #d9d9d9;
  }
  .last-day-content{
      border: none;
  }
  .work-detail{
  color: #C2C2C2;
  line-height: 18px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  }
  .work-title{
  color: var(--secondary-500);
  letter-spacing: -0.36px;
  }
  .training-content {
      border-bottom: 1px solid #d9d9d9;
      padding: 15px 0px;
  }
  .training-card {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(260px, 1fr) );
  }
  
  .training-card-content {
    text-align: start;
  }
  .training-card-head {
    width: 255px;
    border-radius: 12px;
    border: 1px solid #E2E2E2;
  }
  .training-demo {
    height: 190px;
    width: 281px;
    object-fit: cover;
    border-radius: 12px 12px 0px 0px;
  }
  .training-demo-client {
    height: 62px;
    width: 62px;
    object-fit: cover;
    border-radius: 50%;
}
  .training-card-client{
  gap: 10px;
  }
.training-card-head-client {
  width: 18%;
  border-radius: 15px;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.08);
}
.client-training-text{
  color: #979797;
  font-size: 13px;
}
.client-training-head{
  color: #191919;
}
  .training-card-content h4{
    color: var(--black);
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
  }

.top-tab-consent {
  min-height: calc(100vh - 430px);
}
.Upload-text {
  color: #d4dce9;
  font-size: 18px;
}

.Upload-text-content {
  color: #d4dce9;
  font-size: 10px;
}
.preview-docs {
  border-radius: 19px;
  border: 1px solid #b3b3b3;
  width: 200px;
  height: 260px;
  min-width: 200px;
  margin-top: 5px;
}
.preview-content {
  width: 100%;
  height: 90%;
  overflow-x: auto;
  overflow-y: hidden;
}

.preview-docs-cross {
  position: absolute;
  top: 0px;
  right: 0px;
}
.preview-head {
  padding: 20px 30px;
}
.preview-docs-btn {
  border-radius: 4px;
  background: #151829;
  padding: 8px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--white);
  position: absolute;
  bottom: -15px;
  left: 30%;
}
.video-upload {
  width: 100%;
  height: 340px;
  border-radius: 25px;
  border: 1px dashed #909090;
  background: #fafafb;
}
.preview-docs-cross {
  position: absolute;
  top: -15px;
  right: -14px;
}
.preview-docs-btn:hover {
  color: var(--white);
}
.upload-add-btn {
  position: absolute;
  top: 45%;
  left: 25%;
  right: 30%;
  color: #b3b3b3;
  font-weight: 400;
  font-size: 18px;
}
.add-video-file {
  height: 100% !important;
}
.preview-content {
  padding-bottom: 25px;
}
.preview-docs-img {
  border-radius: 19px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height:100%;
}

/* Scrollbar */
::-webkit-scrollbar {
  max-width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 8px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; 
  border-radius: 8px;

}


/* multislect */

.assign-modal-body .input-drop {
  border-radius: 11px 11px 0 0 !important;
  border-bottom: 0 !important ;
  font-size: 14px;
}

.assign-modal-body .common-input [type="checkbox"] {
  transform: scale(1.2);
  accent-color: var(--primary-theme);


}

.drop-list{
  border: 1px solid #D1D1D1 ;
  border-top: 0;
  border-radius:  0 0 11px 11px !important; 
  padding: .375rem .75rem;
  max-height: 200px;
  overflow-y: scroll;
height: auto;
}

.selected-drop-list .drop-list{
  border: 1px solid #D1D1D1 ;
  border-radius:  11px !important; 
  padding: .375rem .75rem;
  max-height: 200px;
  overflow-y: scroll;
height: auto;
margin-top: 20px;
}

.selected-drop-list .drop-list img{
  width: 18px;
}

.drop_wrapper{
  border: 1px solid #D1D1D1 ;
  border-radius:  11px !important; 
  padding: .375rem .75rem;
  display: inline-block;
}

.drop-max-height{
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
}


.days-content p.tabActive {
  font-weight: 600 !important;
}
.inpuprofle-parent .input-profile {
  opacity: 0;
  position: absolute;
  top: 0px;
  z-index: 99;
  width: 126px;
  height: 126px;
  border-radius: 100%;
}
.red-button {
    border-radius: 4px;
    background: #CF0000;
    color: var(--white);
    font-size: 10px; 
    font-style: normal;
    font-weight: 700; 
    line-height: 18px; 
   letter-spacing: -0.24px; 
     padding: 1px 4px; 
}
.green-button {
  border-radius: 4px;
  background: var(--success-600);
  color: var(--white);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.24px;
  padding: 1px 4px;
}